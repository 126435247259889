* {
  box-sizing: border-box;
}

html {
}

body {
  background-color: $background;
  color: $text1;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin: 0;
  line-height: 1.3;
}

.container {
  width: 100%;
  max-width: 1472px;
  margin: 0 auto;
  padding: 0 16px 16px;
}

.page{
  display: flex;
  flex-direction: column;
  height: 100vh;
  .content {
    flex: 1 1 0%;
    height: max-content;
    margin-bottom: auto;
  }

}
