.progress-block {
  background-color: $white;
  border-radius: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  max-width: 550px;
  height: 336px;
}

.progress-block__header {
  border-bottom: 13px solid $text2;
  padding: 25px 0 12px;
  text-align: center;
  font-size: 28px;
  color: $text2;
}

.progress-block__text {
  min-height: 100px;
}

.progress-block__body {
  padding: 60px 25px 20px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}

.progress-block__spinner {
  margin: 15px auto 25px;
  width: max-content;
}

