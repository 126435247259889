@import "../../assets/styles/base/colors";

.print-list.print-list--orders {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 31px auto 0;
  min-height: 150px;

  &.is-disabled::before {
    content: '';
    position: absolute;
    top: 32px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: calc(100% - 32px);
    border-radius: 10px;
    background: rgba($mint, 0.7);
  }
}

.print-list--orders {
  .print-item {
    position: relative;
    display: flex;
    background-color: $white;
    padding: 21px;
    margin-bottom: 22px;
    padding-right: 74px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 3px 6px #00000029;
    border: 0.5px solid #2440533C;
    border-radius: 10px;
    justify-content: space-between;
    transition: all 0.25s;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 3px 15px #00000029;
    }

    &.is-active {
      border: 0.5px solid $active;

      &::before {
        content: '';
        left: 0;
        top: 0;
        position: absolute;
        height: 100%;
        width: 9px;
        background-color: $active;
      }
    }

    &::after {
      content: '';
      right: 100px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      height: 26px;
      width: 13px;
      background: url("../../assets/images/angle-right.svg") no-repeat center;
    }

    &.is-processing {
      &::after {
        content: '';
        z-index: 1;
        top: 0;
        left: 0;
        color: $white;
        position: absolute;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        padding: 30px 0;
        width: 100%;
        height: 100%;
        background-color: rgba($text1, 0.7);
      }
    }

    &.is-released {
      &::after {
        content: '';
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
        background: url("../../assets/images/released.svg") no-repeat center;
        width: 33px;
        height: 33px;
      }
    }
  }

  .labels {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 505px;
  }

  .label {
    font-size: 14px;
    align-self: center;
    margin-left: 28px;
    padding: 4px 12px;
    border: 0;
    outline: 0;
    min-width: 135px;
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: all 0.15s;
    margin-right: 63px;

    &:hover {
      box-shadow: 0 2px 15px rgba($shadow, 0.2);
    }
  }

  .label-legend {
    display: flex;
    width: max-content;
    max-width: 100%;
    margin-left: auto;
    margin-bottom: 14px;

    .label {
      margin-right: 0 !important;
    }
  }

  .label-legend__item {
    display: flex;
    align-items: center;
  }

  .label--small {
    font-size: 12px;
    margin-right: 6px;
    padding: 2px 9px;
  }

  .label--active {
    background-color: $active;
    color: $white;
  }

  .label--stroked-active {
    border: 1px solid $active;
    color: $active;
    background: #fff;

    &.is-active {
      background-color: $active;
      color: $white;
    }
  }

  .label--done {
    //pointer-events: none;
    background-color: $success;
    color: $white;
  }

  .label--stroked-done {
    background: #fff;
    border: 1px solid $success;
    color: $success;

    &.is-active {
      background-color: $success;
      color: $white;
    }
  }

  .label--disabled {
    pointer-events: none;
    background-color: #E2E9E9;
    color: $text3;
  }

  .label--stroked-disabled {
    border: 1px solid #AAC1C4;
    background: #fff;
    color: $text3;

    &.is-active {
      border: 1px solid #E2E9E9;
      background-color: #E2E9E9;
      color: $text3;
    }
  }

  .label--process {
    position: relative;
    background-color: #E2E9E9;
    color: $text3;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      background: url("../../assets/images/timer.svg") no-repeat center;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 100%;
      height: 8px;
      background: url("../../assets/images/progress.png") no-repeat center;
    }
  }

  .label--skipped {
    &::before {
      content: '';
      position: absolute;
      width: 18px;
      height: 20px;
      top: 2px;
      left: 0;
      transform: translate(-50%, -50%);
      background: url("../../assets/images/skipped.svg") no-repeat center;
      background-size: 100%;
    }
  }

  .label--warning {
    &::before {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: $text1;
    }

    &::after {
      content: '!';
      z-index: 1;
      font-size: 11px;
      position: absolute;
      top: 0;
      transform: translate(-73%, -50%);
      left: 1px;
      color: $white;
      font-weight: bold;
    }
  }

  .print-item__hint {
    position: absolute;
    background: $active url("../../assets/images/rocket.svg") no-repeat center 20px;
    padding: 70px 6px 16px;
    color: $white;
    border-radius: 9px;
    top: 110px;
    right: 600px;
    z-index: 3;
    font-size: 14px;
    text-align: center;
    max-width: 134px;

    &::before {
      content: '';
      position: absolute;
      background: $active;
      width: 16px;
      height: 16px;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .print-item__hint-button {
    border: 1px solid $white;
    font-size: 16px;
    font-weight: bold;
    background: $active;
    outline: 0;
    color: $white;
    margin-top: 14px;
    border-radius: 18px;
    cursor: pointer;
    padding: 4px 25px;
    transition: all 0.2s;

    &:hover {
      background: $white;
      color: $active;
    }
  }

  .print-list__filter {
    border: 0;
    color: $text3;
    position: absolute;
    width: 360px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(#000, 0.1);
    top: -12px;
    //overflow: hidden;

    &::before {
      content: url("../../assets/images/search-icon.svg");
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: url("../../assets/images/search-icon.svg") no-repeat center;
      height: 16px;
      width: 16px;
    }
  }

  .print-list__filter-input {
    padding: 11px 6px 11px 36px;
    display: block;
    width: 100%;
    border-radius: 10px;
    height: 100%;
    border: 0;

    &:active {
      outline: none;
    }
  }
}

.print-list__reset {
  position: absolute;
  top: 8px;
  right: 10px;
  background: url("../../assets/images/input-reset.svg") no-repeat center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 0;
  display: block;
  outline: none;

  &:active {
    filter: brightness(80%);
  }
}

@media screen and (max-width:768px) {
  .print-list--orders {
    .print-list__filter {
      position: relative;
      top: 0;
      margin: 10px auto;
      width: calc(100% - 32px);;
    }

    .label-legend{
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto auto;
      margin: 0 auto;
      grid-gap: 5px;
      width: 100%;
      padding: 0 16px;

      .filter-title{
        grid-column: 1;
        grid-row: 1;
      }
      .label{
        min-width: 100px;
        max-width: 120px;
      }
      .label--stroked-active{
        grid-column: 1;
        grid-row: 2;
        margin: 0;
        
      }
      .label--stroked-done{
        grid-column: 2;
        grid-row: 2;
        margin: 0;
      }
      .label--stroked-disabled{
        grid-column: 3;
        grid-row: 2;
        margin: 0;
      }
    }

    .print-file-items {
      margin: 10px;
    }
    .print-item {
      padding: 10px;

      &::after {
        right: 20px;
      }
    }
  }
}