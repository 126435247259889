.sign {
  width: 96px;
  height: 96px;
  margin: 18px auto 12px;
}

.sign--error {
  background: url("../../images/error.svg") no-repeat center;
}

.sign--success {
  background: url("../../images/success.svg") no-repeat center;
}

.sign--forbidden {
  background: url("../../images/forbidden.svg") no-repeat center;
}
