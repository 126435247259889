.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-top: 5px;
}

.button {
  display: block;
  text-align: center;
  outline: 0;
  background-color: $text1;
  color: $white;
  width: max-content;
  margin: 30px auto 0;
  border: 0;
  border-radius: 7px;
  min-width: 219px;
  padding: 12px 15px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 3px 6px rgba($text3, 0.2);
  font-weight: bold;
  transition: all 0.2s;

  &:hover {
    background-color: #3A6380;
  }

  &:active {
    background-color: #192B38;
  }
}

.button:disabled {
  background-color: #7e7d7d;
  color: #fff;
}

.button--round {
  border-radius: 15px;
  box-shadow: none;
  padding: 8px 42px;
}

.button--bottom {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.button--white {
  background-color: $white;
  color: $text1;

  &:hover {
    background-color: $white;
    color: #3A6380;
  }
}

.button--light {
  background-color: $white;
  color: $text2;

  &:hover {
    color: #3A6380;
    background-color: $white;
  }
}

.button--disabled {
  pointer-events: none;
}

.button--orange {
  background-color: $active;
  color: $white;
  padding: 12px 30px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba($text3, 0.2);

  &:hover {
    background-color: $active;
  }
}

.button--dark {
  background: $text1;
  padding: 12px 30px;
}

.button--error {
  &:focus {
    color: $red;
  }
}

.button--modal-orange {
  background-color: $active;
  color: $white;
  font-weight: bold;
  border-radius: 18px;
  border: 1px solid $white;

  &:hover {
    background-color: $active;
    color: $white;
    transform: scale(1.05);
  }
}

.button--modal-white {
  background-color: $white;
  color: $active;
  font-weight: bold;
  border-radius: 18px;
  border: 1px solid $white;

  &:hover {
    background-color: $white;
    color: $active;
    transform: scale(1.05);
  }
}

.button--back {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  position: absolute;
  left: -15px;
  top: 8px;
  min-width: 0;
  text-decoration: underline;
  background: none;
  color: $text1;
  box-shadow: none;
  font-weight: 400;
  text-align: left;
  padding: 0;

  &:hover {
    background: none;
    transform: scale(1.1);
  }
}
