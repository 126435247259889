$mint: #D6E8E8;
$white: #FFFFFF;
$black: #FFFFFF;
$red: #D02525;

$shadow: #2440533C;
$background: #F4F5F5;
$footer-background: #445660;

$text2: #AAC1C4;
$text1: #244053;
$text3: #707070;

$success: #89B655;
$active: #F58823;
$okay: #218067;
