@font-face {
  font-family: zooja-pro,sans-serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Regular'), local('Roboto-Regular'),
  url('../../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Bold'), local('Roboto-Bold'),
  url('../../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Medium'), local('Roboto-Medium'),
  url('../../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Light'), local('Roboto-Light'),
  url('../../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}


